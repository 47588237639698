import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/martijn/workspace/moncau/src/components/layout.js";
import { Container } from "reactstrap";
import { Section } from "../components/section";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Section mdxType="Section">
      <Container mdxType="Container">
        <h2>{`Direcció`}</h2>
        <p>{`Estem al cor del poble de l'Ametlla del Vallès.`}</p>
        <p>{`Carrer Torregassa, 29 - baixos`}<br />{`
08480 L'AMETLLA DEL VALLÈS (Barcelona)`}</p>
        <p>{`Telèfon: 938432421 `}<br />{`
`}<a href="mailto:comandes@canmoncau.cat">{`comandes@canmoncau.cat`}</a></p>
        <iframe width="100%;" height="450" frameBorder="0" style={{
          "borderWidth": "0px"
        }} src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBj5fRvPDGb7tL98weDwwWRb8aRpdvv964&q=Forn moncau, Carrer Torregassa 29, L'AMETLLA DEL VALLÈS" allowFullScreen>
        </iframe>
      </Container>
    </Section>
    <Section className="dark" mdxType="Section">
      <Container mdxType="Container">
        <h1>{`Horari`}</h1>
        <table>
    <tbody>
    <tr>
        <td>Dilluns</td><td>tancat</td>
    </tr>
    <tr>
        <td>Dimarts</td><td>8 a 14 hrs i 17 a 20 hrs</td>
    </tr>
    <tr>
        <td>Dimecres</td><td>8 a 14 hrs i 17 a 20 hrs</td>
    </tr>
    <tr>
        <td>Dijous</td><td>8 a 14 hrs i 17 a 20 hrs</td>
    </tr>
    <tr>
        <td>Divendres</td><td>8 a 14 hrs i 17 a 20 hrs</td>
    </tr>
    <tr>
        <td>Dissabte</td><td>8 a 14 hrs i 17 a 20 hrs</td>
    </tr>
    <tr>
        <td>Diumenge</td><td>8 a 14 hrs</td>
    </tr>
    </tbody>
        </table>
      </Container>
    </Section>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      